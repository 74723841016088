import styled from "@emotion/styled"
import { Alert, Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Snackbar, TextField, Typography } from "@mui/material"
import HouseIcon from "../logo/House"
import PlasterIcon from "../logo/Plaster"
import SawIcon from "../logo/Saw"
import OtherIcon from "../logo/Other"
import ValidIcon from "../logo/Valid"
import { useEffect, useRef, useState } from "react"
import { Auth } from "../../service/auth"

const TextFieldWhite = styled(TextField)({
    "& .MuiFormLabel-root ":{color:'black'}, 
    "& .MuiInputBase-input":{color:'black'},
    "& .MuiFormLabel-root:focused ":{color:'black',fontWeight:'bold'},
    "& .MuiOutlinedInput-notchedOutline":{borderColor:'white'},
    "& .MuiOutlinedInput-root":{backgroundColor:'#F8F7F4'}
})

const CardCheckBox = styled(Box)({
    border:"1px solid #363636",
    transition:'border 0.6s ease,color 0.6s ease',
    width: 150,
    height:150,
    borderRadius:"5px",
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-evenly',
    alignItems:'center',
    position:'relative',
    cursor:'pointer',

    "&:hover ":{
        borderColor:'#137E29',
        color:'#137E29'
    },
    "& svg":{
        transition:'fill 0.6s ease'
    },
    "&:hover svg" :{
        fill:"#137E29"
    }
    
})

const ListSelectWork = [
    {
        icon : <HouseIcon/>,
        label: "couverture"
    },
    {
        icon: <PlasterIcon/>,
        label: "plâtrerie"
    },
    {
        icon: <SawIcon/>,
        label: "charpente"
    },
    {
        icon: <OtherIcon/>,
        label : "autre"
    }
]

function StylisedBox(props){

    const logo = props.logo
    const label = props.label 
    const selected = props.selected
    const setSelected = props.setSelected

    const handleClick = () => {
        setSelected(label)
    }

    return(
        <CardCheckBox onClick={handleClick} >
            {logo}
            <Typography textTransform={"uppercase"} fontWeight={"bold"}>{label}</Typography>
            <Box display={selected === label ? 'initial' : 'none'} position={'absolute'} top={10} right={10}>
                <ValidIcon width={25} height={25}/>
            </Box>
        </CardCheckBox>
    )
}

function ListBox(props){

    const [selected,setSelected] = useState("couverture")
    const handleChange = props.onChange

    useEffect(()=>{
        handleChange({target:{
            value:selected,
            name:"work"
        }})
    },[selected])

    return(
        <>
            <input id="work" name="work" value={selected} style={{display:'none'}}/>            
            {
                ListSelectWork.map(({icon,label},index)=>(
                    <StylisedBox setSelected={setSelected} selected={selected} key={index} label={label} logo={icon}/>
                ))
            }
        </>
    )
}

function Devis(props){

    const [formData, setFormData] = useState({adress: "",city: "",postal: "",work: "",phone:"",email:"",prefered:""});
    const [successSend,setSuccessSend] = useState(false)
    const formRef = useRef()

    const handleSubmit = async (a,b)=>{
        a.preventDefault()
        await Auth.createDevis(formData)
        formRef.current.reset()
        setSuccessSend(true)
    }


    const handleChange = (e) => {
        setFormData({
            ...formData,[e.target.name]:e.target.value
        })
    }

    return(
        <Box p={4} color={'white'} width={"100%"} >
            <Box mb={4}>
                <Typography textAlign={"center"} fontWeight={'bold'} textTransform={'uppercase'} variant="h5">Demande de devis</Typography>
            </Box>

            {/* Form */}
            <Box >
                <form onSubmit={handleSubmit} ref={formRef}>
                <Box display={'flex'} flexWrap={'wrap'} gap={3} justifyContent={'center'} >

                    {/* Adresse */}
                    <Box width={"100%"} maxWidth={350} >
                        <Typography textAlign={'center'} fontWeight={'bold'} textTransform={'uppercase'} >Lieu des travaux</Typography>
                        <Box mt={1} display={'flex'} flexDirection={'column'} >
                            <FormControl variant="outlined" margin="dense" >
                                <FormLabel htmlFor="adresse" sx={{color:"#F8F7F4",mb:1,fontWeight:'bold',textTransform:'uppercase'}}>Adresse :</FormLabel>
                                <TextFieldWhite onChange={handleChange} size="small" id="adress" name="adress"/>
                            </FormControl>          
                            <FormControl variant="outlined" margin="dense" >
                                <FormLabel htmlFor="city" sx={{color:"#F8F7F4",mb:1,fontWeight:'bold',textTransform:'uppercase'}}>Ville</FormLabel>
                                <TextFieldWhite onChange={handleChange}  size="small" id="city" name="city"/>
                            </FormControl>    
                            <FormControl variant="outlined" margin="dense" >
                                <FormLabel htmlFor="postal" sx={{color:"#F8F7F4",mb:1,fontWeight:'bold',textTransform:'uppercase'}}>Code Postale :</FormLabel>
                                <TextFieldWhite onChange={handleChange} size="small" id="postal" name="postal"/>
                            </FormControl>          
                        </Box>
                    </Box>
                    {/* ----------- */}

                    {/* Select work */}
                    <Box width={"100%"} maxWidth={400}  >
                        <Typography textAlign={'center'}  fontWeight={'bold'} textTransform={'uppercase'} >type de travaux  (selectionnez) :</Typography>

                        <Box mt={2} display={'flex'} flexWrap={"wrap"} gap={2} justifyContent={'center'} >

                            <ListBox onChange={handleChange}/>   

                        </Box>
                    </Box>
                    {/* ----------- */}

                    {/* Contact */}
                    <Box width={"100%"} maxWidth={400} >
                        <Typography textAlign={'center'}  fontWeight={'bold'} textTransform={'uppercase'} >Contact</Typography>
                        <Box mt={1} display={'flex'} flexDirection={'column'} >
                            <FormControl variant="outlined" margin="dense" >
                                <FormLabel htmlFor="phone" sx={{color:"#F8F7F4",mb:1,fontWeight:'bold',textTransform:'uppercase'}}>Téléphone :</FormLabel>
                                <TextFieldWhite type="tel" onChange={handleChange} size="small" id="phone" name="phone"/>
                            </FormControl>          
                            <FormControl variant="outlined" margin="dense" >
                                <FormLabel htmlFor="email" sx={{color:"#F8F7F4",mb:1,fontWeight:'bold',textTransform:'uppercase'}}>Email :</FormLabel>
                                <TextFieldWhite type="email" onChange={handleChange} size="small" id="email" name="email"/>
                            </FormControl>    
                            <FormControl variant="outlined" margin="dense" >
                                <FormLabel htmlFor="postal" sx={{"&.Mui-focused":{color:'inherit'},color:"#F8F7F4",mb:1,fontWeight:'bold',textTransform:'uppercase'}}>Prise de contact par :</FormLabel>
                                <RadioGroup onChange={handleChange} name="prefered" id="prefered" defaultValue={"phone"} row>
                                    <FormControlLabel  value={"phone"} control={<Radio sx={{color:'white','&.Mui-checked':{color:'#137E29'}}}/>} label="Telephone"/>
                                    <FormControlLabel  value={"email"} control={<Radio sx={{color:'white','&.Mui-checked':{color:'#137E29'}}}/>} label="Email"/>
                                </RadioGroup>
                            </FormControl>          
                        </Box>
                        <Button variant="contained" size="large" type="submit">Envoyer</Button>

                    </Box>
                    {/* ----------- */}
                     
                </Box>

                </form>
            </Box>
            {/* ----------- */}
            <Snackbar open={successSend} autoHideDuration={6000}>
                <Alert sx={{width:'100%',fontWeight:'bold',textTransform:'uppercase'}} variant="filled" severity="success">
                    Devis envoyé avec succès !
                </Alert>
            </Snackbar>
        </Box>



    )
}

export default Devis