import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActions, CircularProgress } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { useAuth } from '../../provider/user';
import WorkService from '../../service/workService'
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ImageJob(props)
{
    const images  = props.images

    return (
        <Carousel navButtonsAlwaysVisible animation='slide' height={200}  activeIndicatorIconButtonProps={{
          style: {
              backgroundColor: 'green' // 2
          }
          }}>
            {
                images.map( (item, i) => (
                    <Box key={i} height={'100%'} display={'flex'} justifyContent={'center'}>
                        <img  style={{height:'100%',objectFit:'contain',objectPosition:'center'}} src={`${process.env.REACT_APP_API_URL}uploads/${item.name}`} alt="" srcSet="" />
                    </Box>

                ))
            }
        </Carousel>
    )
}

function Work(props){
    const {isAuth} = useAuth()
    const [jobs,setJobs] = useState(undefined)
    const navigate = useNavigate()
    
    useEffect(()=>{
        queryJobs()
    },[])

    const removeJob = async (id) => {
       const success = await WorkService.delete(id)
       if(success){
        queryJobs()
       }
    } 

    const queryJobs = async () => {
        const jobs = await WorkService.getAll()
        setJobs(jobs)
        console.log(jobs)
    }

 
    return(
        <Box p={4} display={'flex'} flexDirection={'column'} rowGap={2}>
            {
                isAuth() && 
                <Button sx={{ alignSelf:'center'}} LinkComponent={Link} to={'/edit-post?action=create'} variant='contained' size='large'>Ajouter un nouveau job</Button>
            }
            {
                jobs === undefined && 
                <CircularProgress size={50} sx={{alignSelf:'center'}}/>
            }
            <Box display={'flex'} rowGap={4} columnGap={4} justifyContent={'center'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'}>
                {
                    jobs && jobs.map((job,index)=>(
                        <Fragment key={index}>
                            <JobCard job={job} onDelete={removeJob}/>
                        </Fragment>
                    ))                
                }
            </Box>
        </Box>
    )
}



function JobCard(props) {

    const {isAuth} = useAuth()
    const navigate = useNavigate()
    const job = props.job
  return (
    <Card key={props.index}  elevation={8} sx={{ width: 345,bgcolor:"#202020",color:'#F8F7F4'}} >
         <CardHeader
            title={<Typography textOverflow={'ellipsis'}  sx={{overflowWrap:'break-word'}} textTransform={'uppercase'} variant='h5'>{job.title}</Typography>}
            subheader={<Typography textTransform={'uppercase'} variant='subtitle2'></Typography>}
        />
        
        <CardContent >
            <ImageJob images={job.images}/>
        </CardContent>
        {
            isAuth() && 
            <CardActions  sx={{justifyContent:'space-evenly',pb:4}}>
                <Button onClick={() => navigate(`/edit-post?action=update&id=${job.id}`)} variant='contained'> Modifier </Button>
                <Button onClick={() => props.onDelete(job.id)} variant='outlined' color='error'>Supprimer</Button>
            </CardActions>
        }
    </Card>
  );
}

export  {Work,JobCard};