import * as React from "react";
const OtherIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="#F8F7F4"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 16.6667C8.16669 16.6667 6.66669 18.1667 6.66669 20.0001C6.66669 21.8334 8.16669 23.3334 10 23.3334C11.8334 23.3334 13.3334 21.8334 13.3334 20.0001C13.3334 18.1667 11.8334 16.6667 10 16.6667ZM30 16.6667C28.1667 16.6667 26.6667 18.1667 26.6667 20.0001C26.6667 21.8334 28.1667 23.3334 30 23.3334C31.8334 23.3334 33.3334 21.8334 33.3334 20.0001C33.3334 18.1667 31.8334 16.6667 30 16.6667ZM20 16.6667C18.1667 16.6667 16.6667 18.1667 16.6667 20.0001C16.6667 21.8334 18.1667 23.3334 20 23.3334C21.8334 23.3334 23.3334 21.8334 23.3334 20.0001C23.3334 18.1667 21.8334 16.6667 20 16.6667Z"
      fill="inherit"
    />
  </svg>
);
export default OtherIcon;
