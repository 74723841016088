import { Box, Fade } from '@mui/material'
import '../style/Carousel.css'


function Carousel(props){

    const width = props.width ? props.width : 'auto'
    const height = props.height ? props.height : 'auto' 
    const number = props.number
    const file = props.file
    const invert = props.invert 
    const lenght = props.lenght ? props.lenght : 2

    return (
        
         
            <Box className="logos" style={{opacity:1}}>

                {[...Array(lenght)].map((e,i)=>(
                      <Box key={i} className={invert ? "logos-slide invert-slide" : "logos-slide"} sx={{
                        "& img":{
                            width:width,
                            height:height,
                            minWidth:100
                        }
                        }}>
                        {
                        [...Array(number)].map((e,i)=>(
                            <img key={i} alt="Un chantier" src={`/img/${file}/${i+1}.jpg`}/>
                        ))
                        }
                    </Box>
                ))}            
            </Box>
    )
}

export default Carousel