import { Token } from '@mui/icons-material'
import api from '../config/api'

class Work {




    static async getAll(){
        const response = await api.get('/jobs')
        const data_parsed = JSON.parse(response.data)
        const {jobs} = data_parsed
        return jobs
    }

    static async createWork(title,description,files){
        const form = new FormData()
        form.append('title',title)
        form.append('description',description)
        const token = JSON.parse(sessionStorage.getItem('token')).token
        const response = await api.post('/jobs',form,{
            headers:{
                Authorization:'bearer '+token
            }
        })
        if(response.status !== 201){
            throw new Error(`Don't created`)
        }

        const {job} = JSON.parse(response.data)
        const imgForm = new FormData()
        files.forEach(file=>{
            imgForm.append("album", file);
          });
        const response_img = await api.post(`/jobs/${job.id}/image`,imgForm,{
            headers:{
                Authorization:'bearer '+token,
                'Content-Type': 'multipart/form-data'
            
            }
        })
        if(response_img.status === 201){
            return true 
        }else{
            return false
        }
    }

    static async delete(id){
        const token = JSON.parse(sessionStorage.getItem('token')).token
        const response = await api.delete(`/jobs/${id}`,{
            headers:{
                Authorization:'bearer '+token
            }
        })
        if(response.status === 200){
            return true 
        }else{
            return false
        }
    }

    static async getWork(id){
        const response = await api.get(`/jobs/${id}`)
        return JSON.parse(response.data)
    }

    static async removeImage(id,imageid){
        const token = JSON.parse(sessionStorage.getItem('token')).token
        const response = await api.delete(`/jobs/${id}/image/${imageid}`,{
            headers:{
                Authorization:'bearer '+token
            }
        })
        if(response.status === 200){
            return true 
        }else{
            return false
        }
    }

    static async updateWord(id,title,description,images){
        const token = JSON.parse(sessionStorage.getItem('token')).token
        const form = new FormData()
        form.append('title',title)
        form.append('description',description)
        const response = await api.put(`/jobs/${id}`,form,{
            headers:{
                Authorization:'bearer '+token
            }
        })

        console.log('Heres',typeof images)
        if( Object.keys(images).length > 0){
            console.log("Pass")
            const imgForm = new FormData()
            images.forEach(file=>{
                imgForm.append("album", file);
              });
            const response_img = await api.post(`/jobs/${id}/image`,imgForm,{
                headers:{
                    Authorization:'bearer '+token,
                    'Content-Type': 'multipart/form-data'
                
                }
            })
        }

        return true
    }
}

export default Work