import { Box, Slide } from "@mui/material";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";


function Reveal ( props ){
    const { ref, inView, entry } = useInView({triggerOnce:true,threshold:0.4});
    const direction = props.dir ? props.dir : 'right'
    const duration = props.duration ? props.duration : 650
    const temporisation = props.temporisation === undefined ? true : props.temporisation
    const [show , setShow]= useState(false)

    useEffect((
    )=>{
        if(inView === true && temporisation === true){
            setShow(true)
        }else{
            setShow(false)
        }

    },[temporisation,inView])


    return(
        <Box ref={ref}>
            <Slide in={show} direction={direction} style={{transitionDuration:duration}}  >
                {props.children}
            </Slide>
        </Box>
    )
}

export default Reveal