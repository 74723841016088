import * as React from "react";
const Heart = (props) => (
  <svg
    width={27}
    height={24}
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.32277 3.12806C9.57157 0.396467 12.0964 9.93022 13.1639 10.8408C14.2313 11.7513 17.3252 0.696862 23.3303 4.40303C29.3355 8.10919 13.6781 22.1337 12.196 21.8207C10.3434 21.4295 -0.926037 5.85966 4.32277 3.12806Z"
      stroke="#137E29"
      strokeWidth={4}
      strokeLinecap="round"
    />
  </svg>
);
export default Heart;
