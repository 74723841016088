import styled from "@emotion/styled";
import { Alert, Box, Button, FormControl, FormLabel, Snackbar, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Auth } from "../../service/auth";

const TextFieldWhite = styled(TextField)({
    "& .MuiFormLabel-root ":{color:'black'}, 
    "& .MuiInputBase-input":{color:'black'},
    "& .MuiFormLabel-root:focused ":{color:'black',fontWeight:'bold'},
    "& .MuiOutlinedInput-notchedOutline":{borderColor:'white'},
    "& .MuiOutlinedInput-root":{backgroundColor:'#F8F7F4'}
})


function PasswordReset(props){
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token')
    const [newPass,setNewPass] = useState({})
    const [errorAlert,setErrorAlert] = useState({show:false,text:'',severity:'info'})
    const navigate = useNavigate()

    const onChange = (e) => {
        setNewPass({...newPass,[e.target.name]:e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(newPass.password === newPass.passwordconfirm && newPass.passwordconfirm){  
            
            const response = await Auth.newPassword(token,newPass.password)

            setErrorAlert({show:true,text:`Mot de passe changé avec succès, retour page de connexion`,severity:'success'})
            const lateRedirect = setTimeout(()=>{
                navigate('/login')
            },1500)
        }else{
            setErrorAlert({show:true,text:`Mot de passe différents ou trop court ( < 8 caractères)`,severity:'error'})
        }
    }

    return(

        <Box p={4} color={'white'}>
            <form onSubmit={handleSubmit}>
                <Box  display={'flex'} flexDirection={'column'} maxWidth={350} rowGap={3}>

                    <FormControl variant="filled"  >
                        <FormLabel htmlFor="password" sx={{color:"#F8F7F4" ,fontWeight:'bold',textTransform:'uppercase'}}>Nouveau mot de passe</FormLabel>
                        <TextFieldWhite onChange={onChange} type="password"  size="small" id="password" name="password"/>
                    </FormControl>
                    <FormControl variant="filled"  >
                        <FormLabel htmlFor="passwordconfirm" sx={{color:"#F8F7F4" ,fontWeight:'bold',textTransform:'uppercase'}}>Confirmation</FormLabel>
                        <TextFieldWhite onChange={onChange} type="password"  size="small" id="passwordconfirm" name="passwordconfirm"/>
                    </FormControl>
                    <Button type="submit" variant="contained" sx={{fontWeight:'bold'}}>Confirmer le nouveau mot de passe</Button>
                    <Typography color={'red'}>{token}</Typography>
                </Box>
            </form>
            <Snackbar open={errorAlert.show} autoHideDuration={6000} onClose={()=>{setErrorAlert(false)}}>
                <Alert
                severity={errorAlert.severity}
                variant="filled"
                sx={{ width: '100%' , fontWeight:'bold'}}
                >
                    {errorAlert.text}
                </Alert>
            </Snackbar>        
        </Box>
    )
}
export default PasswordReset