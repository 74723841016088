import { Box, Typography } from "@mui/material";
import RoofImage from  '../../assets/images/roof.jpg'

function About(props){
    return(
        <Box p={4} height={'100%'} >
            <Typography textTransform={'uppercase'} variant="h4" color={'white'} textAlign={'center'} pb={4}>Qui sommes nous</Typography>
            <img src={RoofImage} alt="" srcset="" style={{width:'100%',height:'auto',maxWidth:800,float:"right",margin:'0 0px 20px 20px'}} />
            {
                text.map((content)=>(
                    <Typography variant="h6" color={'white'} pt={2}>{content}</Typography>
                ))
            }    
        </Box>
    )
}


const text = [
    `Votre partenaire de confiance pour tous vos besoins en couverture. Nous sommes une entreprise spécialisée dans la fourniture de services de couverture de haute qualité pour les propriétaires résidentiels et commerciaux.`,
    `Fondée il y a + de 7ans, notre entreprise s'est engagée à offrir des solutions de toiture fiables, durables et esthétiquement agréables à nos clients. Nous comprenons l'importance cruciale d'une toiture solide pour protéger votre maison ou votre entreprise contre les intempéries et les éléments extérieurs, et c'est pourquoi nous mettons tout en œuvre pour garantir des résultats exceptionnels à chaque projet.`,
    `Hautement qualifié et expérimenté , dévouée à fournir un travail de qualité supérieure, une attention méticuleuse aux détails et un service à la clientèle à chaque étape du processus. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins spécifiques et offrir des solutions sur mesure qui répondent à leurs attentes, tout en respectant les délais et les budgets convenus.`,
    `Nous croyons fermement en l'intégrité, la transparence et l'honnêteté dans tout ce que nous faisons. Nous sommes fiers de notre réputation de fournir un travail de qualité, de respecter nos engagements et de surpasser les attentes de nos clients à chaque fois.`,
    `Que vous ayez besoin de réparations de toiture, de rénovations, d'entretien préventif ou d'une installation complète de nouvelle toiture, faites confiance à D.DOBEL pour un service fiable et professionnel à chaque étape du processus. Contactez-nous dès aujourd'hui pour discuter de vos besoins en matière de couverture et découvrir comment nous pouvons vous aider à protéger votre investissement immobilier.`
]









export default About