import { ThemeProvider } from "@emotion/react";
import { Box, Button, CssBaseline, Typography, useMediaQuery } from "@mui/material";
import './style/App.css'
import theme from "./style/theme";
import Lottie from "lottie-react";
import LogoAnim from "./assets/animations/logo_anim.json"
import Carousel from "./components/Carousel";
import { useEffect, useState } from "react";
import Arrow from "./components/logo/Arrow";
import Heart from "./components/logo/Heart";
import Reveal from "./components/anim/Reveal";
import { Link, RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./components/RootLayout";
import { Work } from "./components/page/Work";
import Devis from "./components/page/Devis";
import About from "./components/page/About";
import Contact from "./components/page/Contact";
import Login from "./components/page/Login";
import WorkEdit from "./components/page/WorkEdit";
import PasswordReset from "./components/page/PasswordReset";
import { AuthProvider } from "./provider/user";

function Hero(){
  const [animationFinished,setAnimationFinished] = useState(false)
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return(
    <Box width={'100%'} display={'flex'} flex={1} py={3} pb={4} flexDirection={'column'} alignItems={'start'}  rowGap={2} overflow={'hidden'} >
        {/* Hero */}
      <Box pt={{md:3}} justifyContent={{sm:'space-evenly'}}  width={'100%'} display={'flex'} flexDirection={{xs:'column',sm:"row-reverse"}}  rowGap={3} alignItems={'start'}>
        <Box  
        sx={{
          height:{xs:200,xl:300},
          width:{xs:200,xl:300}
        }}
        alignSelf={'center'} 
        >
          <Lottie onComplete={(e)=>setAnimationFinished(true)} style={{height:'100%',width:'100%'}} animationData={LogoAnim} loop={false} />
        </Box>
        <Reveal dir={"right"}  >
          <Box px={3} pt={3}>
            <Box color={'#FCFDFF'} >
              <Box>
                <Typography fontWeight='bold' textTransform={'uppercase'} variant={matches ? 'h2' : 'h4'}>Votre <Box component={'span'} sx={{paddingTop:{md:'90px'}}} className="topline">toit</Box>,</Typography>
              </Box>
              <Typography fontWeight='bold' textTransform={'uppercase'} variant={matches ? 'h2' : 'h4'}>Notre <Box component={'span'} sx={{backgroundSize:{md:'100% 16px'}}} className="myunderline">expertise</Box></Typography>

              <Typography color={'#BDBDBD'} pt={2} fontWeight='bold' paragraph variant={matches ? 'h4' : 'body1'}>À votre service depuis + de 7 ans  </Typography>
            </Box>
            <Box display={{xs:'flex'}} flexDirection={'column'} rowGap={2} pt={3}>
              <Button variant="contained" size="large" LinkComponent={Link} to='/contactez-nous' sx={{fontWeight:'bold',borderRadius:'50px' ,width:'fit-content',display:{md:'none'}}}>Contactez-nous</Button>
              <Button variant="outlined" size="large" LinkComponent={Link} to='/devis' sx={{fontWeight:'bold',borderRadius:'50px' , width : 'fit-content',display:{sm:'none'}}}>Décrivez votre projet</Button>
            </Box>
            
          </Box>
        </Reveal>
      </Box>
      <Box width={'100%'} pt={3} display={'flex'} flexDirection={'column'} alignItems={{md:'center'}}>

        <Reveal dir={'left'}   >
            <Box width={'fit-content'}>
              <Box color={'#F8F7F4'} px={3} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography fontWeight={'bold'} textTransform={'uppercase'} variant="h6">Nos réalisations</Typography>
                <Box>
                  <Arrow width={30}/>
                </Box>
              </Box>
            </Box>
        </Reveal>

        <Reveal dir={'up'} duration={650 + 100} >
          <Box>
            <Carousel width={{xs:150,xl:250}} height={{xs:150,xl:250}}   number={5} file={'chantiers'}/>
              <Box width={'100%'} display={'flex'} justifyContent={'center'} mt={2}>
                <Button LinkComponent={Link} to='/nos-chantiers' size="large" variant="outlined" sx={{fontWeight:'bold',borderRadius:'50px'}}>En voir plus</Button>
              </Box>
          </Box>
        </Reveal>

        <Reveal dir={'left'} duration={650 + 400} >
            <Box width={'fit-content'} marginLeft={{xs:'auto',md:'unset'}} pt={5}>
              <Box color={'#F8F7F4'} px={3} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography fontWeight={'bold'} textTransform={'uppercase'} variant="h6" textAlign={'center'}>Fait avec le <span style={{marginInline:3}}><Heart/></span> et le meilleur</Typography>
                <Box pr={5} sx={{transform:'scale(-1, 1)'}}>
                  <Arrow width={30}/>
                </Box>
              </Box>
            </Box>
        </Reveal>

        <Reveal dir={'up'} duration={650 + 400} >
          <Box>
            <Carousel lenght={4} height={{xs:50,xl:70}} invert  number={4} file={'brand'}/>
          </Box>
        </Reveal>

      </Box>
    </Box>
  )
}




function App() {

  const router = createBrowserRouter([{
    path:"/",
    element: <RootLayout/>,
    children:[
      {
        path:'',
        element:<Hero/>
      },
      {
        path:'acceuil',
        element:<Hero/>
      },
      {
        path:'nos-chantiers',
        element:<Work/>
      },
      {
        path:'devis',
        element:<Devis/>
      },
      {
        path:'qui-sommes-nous',
        element:<About/>
      },
      {
        path:'contactez-nous',
        element:<Contact/>
      },
      {
        path:'login',
        element:<Login/>
      },
      {
        path:'edit-post',
        element:<WorkEdit/>
      },
      {
        path:'reset-password',
        element:<PasswordReset/>
      }
  ]
  }])





  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline/>
        <RouterProvider router={router}/>
      </AuthProvider>
    </ThemeProvider>
  );
}



export default App;
