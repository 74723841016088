import * as React from "react";
const HouseIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="#F8F7F4"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.6666 30H18.3333V26.6667H21.6666V30ZM25 23.3333H15V33.3333H25V23.3333ZM31.6666 15.5V6.66667H26.6666V11L20 5L3.33331 20H8.33331L20 9.48333L31.6666 20H36.6666L31.6666 15.5Z"
      fill="inherit"
    />
  </svg>
);
export default HouseIcon;
