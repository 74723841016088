import * as React from "react";
const Arrow = (props) => (
  <svg
    width={37}
    height={42}
    viewBox="0 0 37 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.4268 34.9391C18.6031 32.0047 17.8045 29.4145 17.1694 26.7866C16.8739 25.5666 16.3331 25.3851 15.2012 25.6354C11.9817 26.3612 2.41751 21.9815 0.7323 19.1409C-0.19205 17.583 -0.437246 16.2691 1.16622 14.9051C2.93946 13.4035 4.31023 14.7299 5.13397 15.706C7.11472 18.0397 9.54195 19.2035 12.4596 19.5914C13.7298 19.7603 15.0126 19.8479 16.3645 19.9731C17.1757 16.5256 17.9617 13.2784 18.6912 10.0187C19.5589 6.1395 21.961 3.34273 25.0673 1.0778C26.413 0.0954972 27.9598 -0.392517 29.6262 0.383315C31.2925 1.15915 33.1664 1.68471 34.556 2.80466C37.4297 5.10713 37.3731 7.24695 36.3922 10.9259C34.7887 16.9448 30.211 19.9167 25.3817 22.7761C24.5642 23.2641 23.5518 23.4205 22.2125 23.871C22.8727 26.6427 23.5581 29.502 24.2624 32.4552C27.2996 31.4979 27.7083 29.2392 28.7961 27.4373C29.5004 26.2736 30.7014 24.8283 31.8584 24.6406C33.7134 24.3403 35.1848 25.723 35.6124 27.6501C35.7633 28.3258 35.5433 29.2956 35.1157 29.8524C32.8016 32.8682 30.2173 35.6086 29.3118 39.5753C28.6892 42.322 26.1237 42.4784 23.6084 41.3147C21.2001 40.2073 18.3641 39.8256 15.6728 39.5691C13.7487 39.3814 12.0132 39.1749 11.4724 37.1603C11.2146 36.1842 11.3844 34.4261 12.0069 34.0381C13.0822 33.3687 14.6856 33.206 15.9998 33.3749C17.2071 33.5313 18.3201 34.4011 19.4205 34.9329L19.4268 34.9391ZM22.42 17.4266C22.6589 17.5267 22.9042 17.6268 23.1431 17.7269C26.9726 15.7811 29.8462 12.8905 31.1919 8.75479C31.4686 7.89137 31.0032 6.01437 30.4625 5.83292C29.4375 5.4888 28.0856 5.94554 26.9223 6.25837C26.5135 6.37099 26.1299 7.00919 25.9539 7.4847C24.7465 10.7882 23.5958 14.1105 22.4262 17.4266H22.42Z"
      fill="#137E29"
    />
  </svg>
);
export default Arrow;
