import React, { createContext, useState, useContext, useEffect } from 'react';
import { Auth } from '../service/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  // const [isAuth , setisAuth] = useState(false) 

  const login = async (email, password) => {
    try {
        const {message,token,user : newuser} =  await Auth.attempt(email,password) 
        setUser(newuser) 
        sessionStorage.setItem('token',JSON.stringify(token))
        sessionStorage.setItem('user',JSON.stringify(newuser))
        return true
    } catch (error) {
        console.log(error)
        return false
    }
  };

  const logout = () => {
    setUser(null);
    sessionStorage.clear()
  };

  const isAuth = () => {

      let auth 
      const token_session = sessionStorage.getItem('token')
      if(token_session){
          let {expires_at,token} = JSON.parse(token_session) 
          const token_date = new Date(expires_at)
          auth = token_date > new Date()
      }else{
        auth = false 
      }
    return auth
  }

  const authContextValue = {
    user,
    login,
    logout,
     isAuth
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}
