import styled from "@emotion/styled";
import { Backdrop, Box, Button, CircularProgress, FormControl, FormLabel, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Work from "../../service/workService";
import CloseIcon from '@mui/icons-material/Close';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const TextFieldWhite = styled(TextField)({
    "& .MuiFormLabel-root ":{color:'black'}, 
    "& .MuiInputBase-input":{color:'black'},
    "& .MuiFormLabel-root:focused ":{color:'black',fontWeight:'bold'},
    "& .MuiOutlinedInput-notchedOutline":{borderColor:'white'},
    "& .MuiOutlinedInput-root":{backgroundColor:'#F8F7F4'}
})


const ACTION_TYPE = {
    CREATE : 'create',
    UPDATE : 'update'
}

function WorkEdit(props){

    const [dataForm,setDataForm] = useState({title:'',description:''})
    const [searchParams, setSearchParams] = useSearchParams();
    const [files,setFiles] = useState([])
    const navigate = useNavigate()
    const action = searchParams.get('action');
    const id = searchParams.get('id')
    const [isLoading,setIsLoading] = useState(action === ACTION_TYPE.UPDATE)
    const [imageList,setImageList] = useState([])

    const onChange = (e) => {
        setDataForm({...dataForm,[e.target.name]:e.target.value})
    }

    const onChangeImages = (imgList) => {
        setFiles(imgList)
    }

    const removeFile = (file) => {
        const newarr = files.filter(function(item) {
            return item !== file
        })
        setFiles(newarr)
    }

    const uploadJob = async () => {
        setIsLoading(true)
        if(action === ACTION_TYPE.CREATE){
            const title = dataForm.title
            const description = dataForm.description
            const success = await Work.createWork(title,description,files)

            if(success){
                navigate('/nos-chantiers')
            }
        }else if(action === ACTION_TYPE.UPDATE){
            const success = await Work.updateWord(id,dataForm.title,dataForm.description,files)
            if(success){
                navigate('/nos-chantiers')
            }
        }
        setIsLoading(false)
    }

    useEffect(()=>{
        const queryWork = async () => {
            const response = await Work.getWork(id)
            setDataForm({...response.job})  
            setImageList(response.job.images)
            setIsLoading(false)          
        }

        if(action === ACTION_TYPE.UPDATE && id){
            queryWork()
        }
    },[])


    const removeExistingFile = async (imageid) => {
        const result = await Work.removeImage(id,imageid)
        console.log(result)
        if(result){
            navigate(0)
        }
    }

    return(
        <Box p={4} color={'white'} textAlign={'center'} >
            <Typography textAlign={'center'} fontWeight={'bold'} variant="h6" textTransform={'uppercase'}>{ACTION_TYPE.CREATE === action ? `Création d'un poste` : `Mise à jour d'un poste`}</Typography>
            { isLoading &&
                <CircularProgress  />
            }
            { !isLoading &&
            <Box display={'flex'} flexDirection={'column'} maxWidth={500} mx={'auto'} rowGap={4} mt={2}>
                <FormControl variant="outlined"  >
                    <FormLabel htmlFor="title" sx={{color:"#F8F7F4" ,fontWeight:'bold',textTransform:'uppercase'}}>Titre</FormLabel>
                    <TextFieldWhite value={dataForm.title} onChange={onChange} type="text" size="small" id="title" name="title"/>
                </FormControl>
                <FormControl variant="outlined"  >
                    <FormLabel htmlFor="description" sx={{color:"#F8F7F4" ,fontWeight:'bold',textTransform:'uppercase'}}>Description</FormLabel>
                    <TextFieldWhite value={dataForm.description} onChange={onChange} rows={7} multiline size="small" id="description" name="description"/>
                </FormControl>
                {
                    files.length > 0 &&
                    <Typography fontWeight={'bold'} textTransform={'uppercase'}>Images à ajouter </Typography>
                }
                {
                    files && 
                    files.map((file,index)=>(
                        <Box key={index} width={'70%'} position={'relative'}>
                            <img src={URL.createObjectURL(file)} alt="" srcSet="" style={{width:'100%',height:'auto'}}  />
                            <Box position={'absolute'} top={0} right={0}>
                                <IconButton onClick={()=>removeFile(file)}    >
                                    <CloseIcon color="error" fontSize="large"  />
                                </IconButton>
                            </Box>
                        </Box>
                    ))
                }
                {
                    imageList.length > 0 &&
                    <Typography fontWeight={'bold'} textTransform={'uppercase'}>Images existantes </Typography>
                }
                {
                    imageList.map((image,index)=>(
                        <Box key={index} width={'70%'} position={'relative'}>
                            <img key={index} src={`${process.env.REACT_APP_API_URL}uploads/${image.name}`} alt="" srcSet="" style={{width:'100%',height:'auto'}} />
                            <Box position={'absolute'} top={0} right={0}>
                                <IconButton onClick={()=>removeExistingFile(image.id)} >
                                    <CloseIcon color="error" fontSize="large"  />
                                </IconButton>
                            </Box>
                        </Box>
                    ))
                }
                <InputFileUpload onChange={onChangeImages}/>
                <Button variant="contained" onClick={uploadJob}>Envoyer</Button>
            </Box>
            }
            <Backdrop  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                 <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

function InputFileUpload(props) {

    const onChangeCallback = props.onChange

    const onChange = (e) => {
        const fileList = e.target.files 
        console.log(fileList)
        if(fileList){
            const files = [...fileList]
            onChangeCallback(files)
        }

    }

    return (
      <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
        Ajouter des images
        <VisuallyHiddenInput onChange={onChange} type="file" accept="image/*" multiple />
      </Button>
    );
  }
  

export default WorkEdit