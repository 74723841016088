import * as React from "react";
const MailIco = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M66.6665 13.3333H13.3332C9.6665 13.3333 6.69984 16.3333 6.69984 19.9999L6.6665 59.9999C6.6665 63.6666 9.6665 66.6666 13.3332 66.6666H66.6665C70.3332 66.6666 73.3332 63.6666 73.3332 59.9999V19.9999C73.3332 16.3333 70.3332 13.3333 66.6665 13.3333ZM66.6665 26.6666L39.9998 43.3333L13.3332 26.6666V19.9999L39.9998 36.6666L66.6665 19.9999V26.6666Z"
      fill="#F8F7F4"
    />
  </svg>
);
export default MailIco;
