function Logo(props){
    return(
  <svg
    width={40}
    height={34}
    viewBox="0 0 40 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.796 30.544c-1.923 1.352-4.063 2.156-6.328 2.66-2.18.485-4.394.667-6.623.671-3.668.008-7.336.004-11.004.004-.05 0-.1-.005-.174-.01V22.404L0 26.279v-.272c0-1.59.003-3.18-.001-4.77 0-.133.037-.204.155-.271 2.067-1.198 4.13-2.405 6.197-3.602 2.478-1.434 4.96-2.862 7.44-4.293 2.008-1.158 4.016-2.319 6.022-3.482.122-.07.206-.066.327.005 2.822 1.634 5.647 3.262 8.47 4.892 2.308 1.333 4.612 2.67 6.922 4 1.438.826 2.884 1.64 4.328 2.458.092.052.14.103.139.218-.004 1.642-.003 3.283-.004 4.925 0 .042-.005.084-.009.155-.432-.245-.844-.475-1.254-.71-2.294-1.315-4.59-2.629-6.883-3.949-2.076-1.195-4.148-2.399-6.222-3.598a1539.248 1539.248 0 0 0-5.546-3.193c-.061-.035-.184-.016-.253.023-1.002.57-2 1.147-2.998 1.723-1.83 1.057-3.66 2.115-5.492 3.17-.095.054-.133.11-.133.223.003 3.2.002 6.4.003 9.599 0 .044.006.088.011.157h.289c2.398 0 4.796.007 7.194-.004 1.678-.008 3.34-.17 4.969-.594 1.822-.472 3.498-1.237 4.942-2.465a9.814 9.814 0 0 0 1.96-2.255c.02-.032.042-.062.076-.11l3.906 2.256c-1.005 1.589-2.23 2.94-3.76 4.03Z"
      fill="#137E29"
    />
    <path
      d="M34.188 6.688c1.962 2.784 2.728 5.92 2.855 9.292l-1.157-.665c-1.226-.708-2.453-1.415-3.675-2.13a.427.427 0 0 1-.18-.24c-.468-1.915-1.296-3.64-2.679-5.07-1.003-1.038-2.187-1.81-3.507-2.38-1.465-.632-3-.99-4.576-1.193-1.249-.161-2.503-.206-3.76-.204-1.995.004-3.99.001-5.984.001h-.316v.229c0 2.212-.003 4.424.004 6.636 0 .173-.055.26-.2.343-1.418.81-2.832 1.63-4.246 2.445-.021.012-.045.02-.088.038V.012c.06-.004.116-.01.172-.01 3.854.001 7.708-.009 11.562.007 2.548.01 5.065.293 7.516 1.026 2.667.798 5.054 2.08 6.984 4.122.452.479.846 1.012 1.275 1.531Z"
      fill="#FCFDFF"
    />
    <path
      d="M17.203 25.619v-2.47h2.46v2.47h-2.46Zm5.539-2.485v2.474H20.28v-2.474h2.462Z"
      fill="#F4F9FF"
    />
    <path
      d="M19.674 22.572h-2.473v-2.447h2.473v2.447Zm3.07-2.46v2.46h-2.462v-2.46h2.462Z"
      fill="#fff"
    />
  </svg>

    )
}
export default Logo