import { createTheme } from "@mui/material";

const theme = createTheme({
    typography:{
        fontFamily:'Praktika'
    },
    palette:{
        primary:{
            main:'#137E29'
        }
    }
})


export default theme