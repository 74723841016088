import * as React from "react";
const PlasterIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="#F8F7F4"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.6 14.9L12.7 0L10.35 2.35L14.3166 6.31667L5.73331 14.9C4.74998 15.8833 4.74998 17.4667 5.73331 18.4333L14.9 27.6C15.3833 28.0833 16.0333 28.3333 16.6666 28.3333C17.3 28.3333 17.95 28.0833 18.4333 27.6L27.6 18.4333C28.5833 17.4667 28.5833 15.8833 27.6 14.9ZM8.68331 16.6667L16.6666 8.68333L24.65 16.6667H8.68331ZM31.6666 19.1667C31.6666 19.1667 28.3333 22.7833 28.3333 25C28.3333 26.8333 29.8333 28.3333 31.6666 28.3333C33.5 28.3333 35 26.8333 35 25C35 22.7833 31.6666 19.1667 31.6666 19.1667ZM3.33331 33.3333H36.6666V40H3.33331V33.3333Z"
      fill="inherit"
    />
  </svg>
);
export default PlasterIcon;
