import * as React from "react";
const PhoneIco = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.0667 35.9667C26.8667 45.4 34.6 53.1 44.0333 57.9333L51.3667 50.6C52.2667 49.7 53.6 49.4 54.7667 49.8C58.5 51.0333 62.5333 51.7 66.6667 51.7C68.5 51.7 70 53.2 70 55.0333V66.6667C70 68.5 68.5 70 66.6667 70C35.3667 70 10 44.6333 10 13.3333C10 11.5 11.5 10 13.3333 10H25C26.8333 10 28.3333 11.5 28.3333 13.3333C28.3333 17.5 29 21.5 30.2333 25.2333C30.6 26.4 30.3333 27.7 29.4 28.6333L22.0667 35.9667Z"
      fill="#F8F7F4"
    />
  </svg>
);
export default PhoneIco;
