import api from '../config/api'

 
class Auth{

   static async attempt(email,password) {

        const form = new FormData()
        form.append('email',email)
        form.append('password',password)

        const response = await api.post('login',form);
        
        const parsed = JSON.parse(response.data)
        if(response.status === 401){
            throw new Error(parsed.message)
        }

        return parsed
    }

    static async newPassword(token,password){
        const form = new FormData()
        form.append('token',token)
        form.append('password',password)
         await api.post('newpassword',form)
    }

    static async resetPassword(email){
        const form = new FormData()
        form.append('email',email)
          await api.post('reset',form)
    }

    static async createDevis({adress,city,postal,work,phone,email,token,prefered}){
        const form = new FormData()
        form.append('adress',adress)
        form.append('city',city)
        form.append('postal',postal)
        form.append('work',work)
        form.append('phone',phone)
        form.append('email',email)
        form.append('token',token)
        form.append('prefered',prefered)
        await api.post('mail',form)
        
    }

}


export {Auth}