import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Logo from "./logo/Logo";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Link as RealLink, Stack } from "@mui/material";
import BusinessSharpIcon from '@mui/icons-material/BusinessSharp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { AuthProvider, useAuth } from "../provider/user";



const drawerWidth = 240;
const navigation = [
  {
    title:"Acceuil",
    path:"acceuil"
  },
  {
    title:"Nos chantiers",
    path:"nos-chantiers"
  },
  {
    title:"Devis",
    path:"devis"
  },
  {
    title:"Qui sommes-nous",
    path:"qui-sommes-nous"
  },

]

function RootLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {pathname} = useLocation();
  const {isAuth,logout} = useAuth()

  React.useEffect(()=>{
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:'instant'
    })
  },[pathname])

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }} display={'flex'} flexDirection={'column'} height={'100%'} color={'#F8F7F4'}>
      <Box py={2} px={2} display={'flex'} justifyContent={'center'} columnGap={2} alignItems={'center'}>
        <Box display={'flex'} justifyContent={'center'}>
          <Logo/>
        </Box>
        <Typography variant="h4" fontWeight={"bold"} component="div">
          <span style={{ color: "green" }}>D-</span>
          DOBEL
        </Typography>
        
      </Box>
      <Divider sx={{borderColor:'#363636'}} />
      <List>
        {navigation.map(({title,path},index) => (
          <ListItem key={index} disablePadding >
            <ListItemButton LinkComponent={Link} to={path} sx={{ textAlign: "left" }}>
              <ListItemText  primary={
                <Typography textTransform={'uppercase'} fontWeight={"bold"}>
                  {title}
                </Typography>
              } />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{borderColor:'#363636'}} />
      <Box flex={1} display={'flex'} flexDirection={'column'} p={2} justifyContent={'space-between'}>
        <Button
                variant="contained"
                LinkComponent={Link}
                to='/contactez-nous'
                sx={{ fontWeight: "bold", borderRadius: 50 }}
              >
                Contactez-nous
        </Button>
        {
          isAuth() &&
          <Button onClick={()=>logout()} variant="outlined" color="error" sx={{borderRadius : 50}}>
            Se deconnecter
          </Button>
        }
      </Box>
            

    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
      <Box className="App" sx={{ display: "flex", bgcolor: "#171717" }}>
        <CssBaseline />
        <AppBar
          elevation={0}
          sx={{ bgcolor: "transparent",backdropFilter:'blur(20px)', borderBottom: "3px solid #363636" }}
          component="nav"
        >
          <Toolbar sx={{ alignItems: "stretch" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              display={{ xs: "flex", sm: "none" }}
              ml={-5}
              justifyContent={"center"}
              alignItems={"center"}
              flexGrow={1}
            >
              <Typography variant="h4" fontWeight={"bold"} component="div">
                <span style={{ color: "green" }}>D-</span>
                DOBEL
              </Typography>
            </Box>

            <Box
              display={{ xs: "none", sm: "flex" }}
              alignItems={"center"}
              borderRight={"3px solid #363636"}
              paddingRight={3}
            >
              <Typography variant="h4" fontWeight={"bold"} component="div">
                <span style={{ color: "green" }}>D-</span>
                DOBEL
              </Typography>
            </Box>
            <Box
              display={{ xs: "none", sm: "flex" }}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ flexGrow: 1 }}
            >
              <Box display={'flex'} flex={1} justifyContent={'space-evenly'}>
                {navigation.map(({title,path},index) => (
                  <Button
                    LinkComponent={Link}
                    to={path}
                    key={index}
                    sx={{
                      color: index === 0 ? "#fff" : "#737171",
                      fontWeight: "bold",
                      "&:hover": { color: "#fefefe" },
                    }}
                  >
                    {title}
                  </Button>
                ))}
              </Box>
            </Box>

            <Box
              className={"NavBar-CTA"}
              borderLeft={"3px solid #363636"}
              display={"flex"}
              alignItems={"center"}
              paddingLeft={3}
            >
              <Button
                variant="contained"
                LinkComponent={Link}
                to='/contactez-nous'
                sx={{ fontWeight: "bold", borderRadius: 50 }}
              >
                Contactez-nous
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                bgcolor:'#171717'
              },
              
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Box component="main" display={'flex'} flexDirection={'column'} sx={{  width: "100%" }}>
          <Box flex={1}>
            <Toolbar />
            <Outlet/>
          </Box>
          <BottomBar/>
        </Box>
      </Box>
  );
}

function BottomBar(){
  return(
    <AppBar position="relative" style={{backgroundColor:'transparent',borderTop:"3px solid #363636" }} >
      <Toolbar sx={{display:'flex',flexDirection:'column',py:2}} >
        <Box>
          <Typography textTransform={'uppercase'}>Retrouver-nous ici:</Typography>
        </Box>
        <Stack direction={'row'}>
        <IconButton size="large"  sx={{color:'#F8F7F4'}} onClick={()=> window.location.href = 'https://www.facebook.com/profile.php?id=100087254625394'}> 
            <FacebookIcon  fontSize="inherit"/>
          </IconButton>
          {/* <IconButton size="large"  sx={{color:'#F8F7F4'}}>
            <InstagramIcon  fontSize="inherit"/>
          </IconButton> */}
          <IconButton size="large"  sx={{color:'#F8F7F4'}} onClick={()=>window.location.href = 'https://annuaire-entreprises.data.gouv.fr/entreprise/dylan-dobel-820838225'}>
            <BusinessSharpIcon  fontSize="inherit"/>
          </IconButton>
        </Stack>
        <Box sx={{alignSelf:'start'}} width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
          <Box   >
            <Typography textTransform={'uppercase'} variant="body2" >Site réalisé par : <RealLink href="https://www.limitlesslogic.fr" fontStyle={'italic'} >limitlesslogic.fr</RealLink></Typography>
          </Box>
          <Button size="small" LinkComponent={Link} to={'/login'} sx={{p:0}} >
              connexion
          </Button>
        </Box>
        
      </Toolbar>
    </AppBar>
  )
}

export default RootLayout;
