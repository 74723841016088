import { Axios } from "axios";

const axiosinstance = new Axios({
    baseURL:process.env.REACT_APP_API_URL,
    headers:{
        Accept:'application/json'
    }
})

// axiosinstance.interceptors.response.use((response) => {

// })

export default axiosinstance