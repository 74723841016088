import styled from "@emotion/styled"
import { Alert, Box, Button, FormControl, FormLabel, Snackbar, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useAuth } from "../../provider/user"
import { useNavigate } from "react-router-dom"
import { Auth } from "../../service/auth"

const TextFieldWhite = styled(TextField)({
    "& .MuiFormLabel-root ":{color:'black'}, 
    "& .MuiInputBase-input":{color:'black'},
    "& .MuiFormLabel-root:focused ":{color:'black',fontWeight:'bold'},
    "& .MuiOutlinedInput-notchedOutline":{borderColor:'white'},
    "& .MuiOutlinedInput-root":{backgroundColor:'#F8F7F4'}
})

function Login(){

    const [dataForm , setDataForm] = useState([])
    const {login,isAuth,logout} = useAuth()
    const [alertMessage,setAlertMessage] = useState('')
    const [alertShow,setAlertShow] = useState(false)
    const [alertSeverity,setAlertSeverity] = useState('info')
    const navigate = useNavigate()

    const onChange = (e) =>{
        setDataForm({...dataForm,[e.target.name]:e.target.value})        
    }

    const attemptlogin = async (e) => {
        e.preventDefault()

        const {email,password} = dataForm
        const isauth  = await login(email,password)
        setAlertShow(true)
        if(isauth){
            setAlertMessage('Connexion réussie')
            setAlertSeverity('success')
            navigate('/nos-chantiers')

        }else{
            setAlertMessage('Erreur email/mot de passe')
            setAlertSeverity('error')
        }
    }

    const attemptReset = async (e) => {
        const {email} = dataForm
        if(email){
            const result = await Auth.resetPassword(email)
            setAlertShow(true)
            setAlertSeverity('info')
            setAlertMessage(`Lien envoyé par mail`)
        }else{
            setAlertShow(true)
            setAlertSeverity('error')
            setAlertMessage(`Saisir un email`)

        }
    }

    const doLogout =  (e) => {
        logout()
        setAlertShow(true)
        setAlertSeverity('info')
        setAlertMessage(`Deconnecté`)
    }

    return(
    <Box p={4} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
    {
        !isAuth() &&
        <form>
            <Box display={'flex'} flexDirection={'column'} rowGap={3} maxWidth={350}> 
                <Typography textAlign={'center'} color={'#F8F7F4'} variant="h5" fontWeight={'bold'}
                textTransform={'uppercase'}>Connexion</Typography>

                <FormControl variant="outlined"   >
                    <FormLabel htmlFor="email" sx={{color:"#F8F7F4",fontWeight:'bold',textTransform:'uppercase'}}>Email</FormLabel>
                    <TextFieldWhite onChange={onChange}  type="email" size="small" id="email" name="email"/>
                </FormControl>
                <FormControl variant="outlined"  >
                    <FormLabel htmlFor="password" sx={{color:"#F8F7F4" ,fontWeight:'bold',textTransform:'uppercase'}}>Mot de passe</FormLabel>
                    <TextFieldWhite onChange={onChange} type="password" size="small" id="password" name="password"/>
                </FormControl>
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <Button onClick={attemptlogin} type="submit" variant="contained" >Se connecter</Button>
                    <Button onClick={attemptReset} variant="outlined">Mot de passe oublié</Button>
                </Box>
            </Box>
        </form>
    }
    {
        isAuth() &&
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            <Button onClick={doLogout} type="submit" variant="contained" color="error" >Se deconnecter</Button>
        </Box>

    }
        <Snackbar open={alertShow} onClose={()=>setAlertShow(false)} autoHideDuration={6000} >
            <Alert
            severity={alertSeverity}
            variant="filled"
            sx={{ width: '100%' }}
            >
                {alertMessage}
            </Alert>
        </Snackbar>
    </Box>
    )

}


export default Login