import * as React from "react";
const ArrowIco = (props) => (
  <svg
    width={64}
    height={90}
    viewBox="0 0 64 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.9928 67.7592C29.5091 63.2478 34.166 57.6919 38.5561 51.9819C46.1053 42.1628 51.8899 31.3335 54.7822 19.2715C55.9701 14.3149 55.8066 9.03294 56.2628 3.90509C56.4522 1.78204 56.8911 -0.0585012 59.6973 0.00142355C62.3055 0.0527876 64.3801 1.95326 63.9411 4.7098C63.02 10.4711 62.3142 16.3609 60.4893 21.8569C57.795 29.9553 54.963 38.1907 50.7623 45.5614C46.6735 52.7353 41.0008 59.0787 35.6208 65.4393C32.8059 68.7608 29.1734 71.4061 25.7904 74.2311C23.8536 75.8491 23.6557 77.4071 24.6198 79.7356C25.5925 82.0898 26.152 84.7094 26.2983 87.2519C26.35 88.1679 24.6284 90.017 23.7417 89.9999C19.6787 89.94 15.6244 89.3407 11.5701 88.9983C9.69351 88.8356 7.77388 88.5702 5.93177 88.8099C3.3838 89.1352 0.818648 88.8956 0.155832 86.3702C-0.317608 84.581 0.345185 82.0556 1.36954 80.3863C4.16714 75.8577 7.46399 71.6372 10.5026 67.2541C11.1396 66.3296 11.5787 65.251 12.0005 64.1895C12.8871 61.9466 13.9286 59.8919 16.8381 60.0717C19.0676 60.2087 22.7174 63.5902 23.0015 65.7047C23.0703 66.2097 23.0101 66.7319 23.0101 67.7763L22.9928 67.7592Z"
      fill="#137E29"
    />
  </svg>
);
export default ArrowIco;
