import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import BusinessIco from '../logo/Contact/Business'
import PhoneIco from '../logo/Contact/Phone'
import MailIco from '../logo/Contact/Mail'
import FacebookIco from '../logo/Contact/Facebook'
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ArrowIco from "../logo/Contact/Arrow";
import { useAuth } from "../../provider/user";

const CardBox = styled(Box)({
    border:"1px solid #363636",
    transition:'border 0.6s ease,color 0.6s ease',
    width: 200,
    height:200,
    borderRadius:"5px",
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-evenly',
    alignItems:'center',
    position:'relative',
    cursor:'pointer',

    "&:hover ":{
        borderColor:'#137E29',
        color:'#137E29'
    },
    "& svg":{
        transition:'fill 0.6s ease'
    },
    "&:hover svg" :{
        fill:"#137E29"
    }
    
})


function Contact(props){

    const phoneRef= useRef()

    return(
        <Box p={4} display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'calc(100% - 64px)'}>
            <Box pb={4} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography color={'white'} textTransform={'uppercase'} fontWeight={'bold'} variant='h5'>Contactez-nous :</Typography>
                <Box display={'flex'}>
                    <Typography color={'white'} textTransform={'uppercase'} fontWeight={'bold'} variant='body2'>(cliquez)</Typography>
                    <ArrowIco style={{marginTop:15,marginLeft:-35}}/>
                </Box>
            </Box>
            <Box display={'flex'} rowGap={4} columnGap={4} justifyContent={'center'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'}>
                <CardBox onClick={()=> window.location.href = 'tel:0610960054'}>
                    <Typography textTransform={'uppercase'} fontWeight={'bold'} color={'white'}>Téléphone</Typography>
                    <PhoneIco width={50} height={50}/>
                    <Typography sx={{textDecoration:'none'}} ref={phoneRef} component={'a'}  href="tel:0610960054" textTransform={'uppercase'} variant='subtitle2' color={'white'}>0610960054</Typography>
                </CardBox>
                <CardBox onClick={()=> window.location.href = 'mailto:dylan-dobel@outlook.fr?subject=Contact Devis'}>
                    <Typography textTransform={'uppercase'} fontWeight={'bold'} color={'white'}>Mail</Typography>
                    <MailIco width={50} height={50}/>
                    <Typography textTransform={'uppercase'} variant='subtitle2' color={'white'}>dylan-dobel@outlook.fr</Typography>
                </CardBox>
                <CardBox onClick={()=> window.location.href = 'https://www.facebook.com/profile.php?id=100087254625394'}>
                    <Typography textTransform={'uppercase'} fontWeight={'bold'} color={'white'}>Facebook</Typography>
                    <FacebookIco width={50} height={50}/>
                    <Typography textTransform={'uppercase'} variant='subtitle2' color={'white'}>D.DOBEL</Typography>
                </CardBox>
                <CardBox onClick={()=>window.location.href = 'https://annuaire-entreprises.data.gouv.fr/entreprise/dylan-dobel-820838225'}>
                    <Typography textTransform={'uppercase'} fontWeight={'bold'} color={'white'}>Entreprise</Typography>
                    <BusinessIco width={50} height={50}/>
                    <Typography textTransform={'uppercase'} variant='subtitle2' color={'white'}>entreprise-gouv</Typography>
                </CardBox>
            </Box>
        </Box>
    )
}

export default Contact