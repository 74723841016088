import * as React from "react";
const ValidIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 3.3335C10.8 3.3335 3.33331 10.8002 3.33331 20.0002C3.33331 29.2002 10.8 36.6668 20 36.6668C29.2 36.6668 36.6666 29.2002 36.6666 20.0002C36.6666 10.8002 29.2 3.3335 20 3.3335ZM16.6666 28.3335L8.33331 20.0002L10.6833 17.6502L16.6666 23.6168L29.3166 10.9668L31.6666 13.3335L16.6666 28.3335Z"
      fill="#137E29"
    />
  </svg>
);
export default ValidIcon;
