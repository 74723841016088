import * as React from "react";
const BusinessIco = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.9998 23.3333V10H6.6665V70H73.3332V23.3333H39.9998ZM19.9998 63.3333H13.3332V56.6667H19.9998V63.3333ZM19.9998 50H13.3332V43.3333H19.9998V50ZM19.9998 36.6667H13.3332V30H19.9998V36.6667ZM19.9998 23.3333H13.3332V16.6667H19.9998V23.3333ZM33.3332 63.3333H26.6665V56.6667H33.3332V63.3333ZM33.3332 50H26.6665V43.3333H33.3332V50ZM33.3332 36.6667H26.6665V30H33.3332V36.6667ZM33.3332 23.3333H26.6665V16.6667H33.3332V23.3333ZM66.6665 63.3333H39.9998V56.6667H46.6665V50H39.9998V43.3333H46.6665V36.6667H39.9998V30H66.6665V63.3333ZM59.9998 36.6667H53.3332V43.3333H59.9998V36.6667ZM59.9998 50H53.3332V56.6667H59.9998V50Z"
      fill="#F8F7F4"
    />
  </svg>
);
export default BusinessIco;
