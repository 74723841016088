import * as React from "react";
const SawIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="#F8F7F4"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.8834 23.7167L11.6667 2.5L5.18335 8.98333L18.7334 28.4333C17.4334 29.7333 17.4334 31.85 18.7334 33.15L21.0834 35.5C22.3834 36.8 24.5 36.8 25.8 35.5L32.8667 28.4333C34.1834 27.1333 34.1834 25.0167 32.8834 23.7167ZM23.45 33.1333L21.1 30.7833L28.1667 23.7167L30.5167 26.0667L23.45 33.1333Z"
      fill="inherit"
    />
  </svg>
);
export default SawIcon;
