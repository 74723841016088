import * as React from "react";
const FacebookIco = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6667 10H63.3333C65.1014 10 66.7971 10.7024 68.0474 11.9526C69.2976 13.2029 70 14.8986 70 16.6667V63.3333C70 65.1014 69.2976 66.7971 68.0474 68.0474C66.7971 69.2976 65.1014 70 63.3333 70H16.6667C14.8986 70 13.2029 69.2976 11.9526 68.0474C10.7024 66.7971 10 65.1014 10 63.3333V16.6667C10 14.8986 10.7024 13.2029 11.9526 11.9526C13.2029 10.7024 14.8986 10 16.6667 10ZM60 16.6667H51.6667C48.5725 16.6667 45.605 17.8958 43.4171 20.0838C41.2292 22.2717 40 25.2391 40 28.3333V36.6667H33.3333V46.6667H40V70H50V46.6667H60V36.6667H50V30C50 29.1159 50.3512 28.2681 50.9763 27.643C51.6014 27.0179 52.4493 26.6667 53.3333 26.6667H60V16.6667Z"
      fill="#F8F7F4"
    />
  </svg>
);
export default FacebookIco;
